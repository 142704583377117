import React from 'react'
import styled from 'styled-components'
import MetaHeader from '../../components/MetaHeader'
import Layout from '../../layouts'
import PageIntro from '../../components/PageIntro'
import PageWrapper from '../../components/PageWrapper'
import GridTwo from '../../components/GridTwo'
import TextBlock from '../../components/TextBlock'
import Button from '../../components/Button'
import WhatIsItContent from '../../components/angel-academy/WhatIsItContent'

import { graphql } from 'gatsby'

const CTA = styled.div`
  margin-bottom: 3rem;
`
const CTAButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
`
const WhatIsIt = ({ data }) => {
  const {
    contentfulAngelAcademyPage: {
      pageIntro: {
        heading,
        subheading,
        image,
        metaTitle,
        metaSiteUrl,
        metaDescription,
        metaKeywords,
        metaTwitter,
      },
    },
  } = data

  return (
    <Layout>
      <MetaHeader
        title={metaTitle || heading}
        image={image?.file?.url}
        siteUrl={metaSiteUrl}
        description={metaDescription?.metaDescription}
        keywords={metaKeywords?.metaKeywords}
        twitter={metaTwitter}
      />

      <PageWrapper>
        <PageIntro
          title={heading}
          subtitle={subheading}
          imgSrc={image?.file?.url}
          alt={image?.title}
        />
      </PageWrapper>

      <PageWrapper>
        <WhatIsItContent />
      </PageWrapper>

      <PageWrapper>
        <CTA>
          <TextBlock center>
            <GridTwo>
              <CTAButton to="/angel-academy#apply-now">Sign me up!</CTAButton>
              <CTAButton to="/angel-academy/how-it-works" outline>
                Tell Me More
              </CTAButton>
            </GridTwo>
          </TextBlock>
        </CTA>
      </PageWrapper>
    </Layout>
  )
}

export default WhatIsIt

export const query = graphql`
  query {
    contentfulAngelAcademyPage(
      id: { eq: "9f202887-9c17-5177-bc7f-41908ff4a80d" }
    ) {
      pageIntro {
        heading
        subheading
        image {
          file {
            url
          }
        }
        metaTitle
        metaDescription {
          metaDescription
        }
        metaKeywords {
          metaKeywords
        }
        metaSiteUrl
        metaTwitter
      }
    }
  }
`
